<template>
  <div class="pay_box">
    <Skeleton v-if="flag" :row="20" animate title />
    <div v-else>
      <div class="addr_info">
        <div v-if="showConsignee" class="add_addr">
          <button class="btn_addr" @click="goAddress">+ 添加收货地址</button>
        </div>
        <div v-else class="addressee" @click="goAddress">
          <div>
            <div class="namephone">
              <div class="name_phone">
                <span class="name">{{
                  detailData.shippingInformation.name
                }}</span>
                <span class="phone">{{
                  detailData.shippingInformation.tel
                }}</span>
              </div>
            </div>
            <div class="address">
              <div class="address_txt">
                {{ detailData.shippingInformation.address }}
              </div>
            </div>
          </div>
          <div class="right_arrow">
            <img :src="require('@img/arrow_right_grey.png')" alt="" />
          </div>
        </div>
        <img :src="require('@img/order/group.jpg')" class="group" alt="" />
      </div>
      <div
        v-if="detailData.patientId || detailData.personName"
        class="completed_pre"
      >
        <div class="completed_info">
          <p class="completed_title">处方信息</p>
          <p class="completed_name">
            {{ detailData.drugManInformation.name || detailData.personName }}
            <span
              v-show="
                detailData.drugManInformation.name || detailData.personName
              "
              class="tag"
            >
              用药人
            </span>
          </p>
          <!-- 纸质图片 -->
          <p class="completed_txt">已上传处方</p>
          <p v-if="detailData.prescriptionType === 1" class="completed_txt">
            <span v-for="dis in detailData.diseaseList" :key="dis.diseaseName">
              {{ dis.diseaseName }}</span
            >
          </p>

          <p v-if="detailData.prescriptionType === 1" class="completed_txt">
            <span v-show="detailData.hasTake">无禁忌症;</span>
            <span v-show="!detailData.hasAllergy">无过敏史;</span>
            <span v-show="!detailData.hasAdverse"> 无不良反应;</span>
          </p>
        </div>
      </div>

      <div v-if="detailData.product.length" class="card">
        <CommonTitle
          :title="
            (detailData.product.length && detailData.product[0].merchantName) ||
            ''
          "
        />
        <div class="drug_list">
          <DrugItem
            v-for="drugData in detailData.product"
            :key="drugData.goodsId"
            :data="drugData"
            :is-pay="isMember > 0 && drugData.memberPrice > 0 ? false : true"
          >
            <template v-slot:numOrCount>
              <p class="num">x {{ drugData.count }}</p>
            </template>
          </DrugItem>
          <Cost
            :dis-cos="detailData.freight"
            :sum-money="
              isMember && containPrice && Number(memberPrice) > 0
                ? Number(memberPrice)
                : Number(productSum)
            "
          />
        </div>
      </div>

      <div class="common_info">
        <CommonTitle title="会员卡" @commonTitleClick="handleCouponCellClick">
          <template v-slot:orderStatus>
            <div v-show="couponCellStatus === 0" class="coupon">暂无可用</div>
            <div
              v-show="hasSelectedCardAmount && couponCellStatus === 1"
              class="card_amount"
            >
              -¥{{ hasSelectedCardAmount.toFixed(2) }}
            </div>
            <div
              v-show="hasSelectedCardAmount === 0 && couponCellStatus === 2"
              class="unselected_coupon"
            >
              {{ Number(defaultDeductAmount).toFixed(2) }}元可用
            </div>
            <div class="arrow_container">
              <img
                :src="require('@img/arrow_right_grey.png')"
                class="arrow_right"
              />
            </div>
          </template>
        </CommonTitle>
      </div>

      <div class="common_info">
        <CommonTitle title="需要发票 (个人)">
          <template v-slot:orderStatus>
            <div class="sw">
              <MySwitch
                v-model="switchChecked"
                active-color="#f36218"
                inactive-color="#DCDFE4"
                size="30"
                @change="switchChange"
              />
            </div>
          </template>
        </CommonTitle>

        <div v-show="switchChecked">
          <Field
            v-model.trim="detailData.invoice"
            label="发票抬头"
            maxlength="32"
            label-class="self_label"
            placeholder="请输入个人发票抬头"
            @blur="blurInvoice"
          />
        </div>
      </div>

      <div class="common_info purchase_info">
        <CommonTitle title="购买须知" />
        <div class="purchase">
          <p>1. 选购处方药，需上传处方单图片</p>
          <p>
            2. 如需客服协助，请致电热线<a :href="'tel:' + '400 052 3990'"
              >400 052 3990</a
            >
          </p>
          <p>3. 人工客服工作时间：周一~周五09:00-18:00</p>
          <p v-if="!isJian1Bao">4. 订单满{{ packageMailAmount }}元包邮</p>
        </div>
      </div>

      <div class="footer_btns">
        <div class="sum_btn">
          <span class="sum_txt">实付:</span>
          <span v-if="isMember === '1'">
            <span class="money"
              ><span class="icon_money">¥</span>{{ actualPrice }}</span
            >
            <span
              v-if="isMember === '1' && containPrice"
              :class="{ origin_price: isMember === '1' }"
              class="money"
              >￥{{ allSum }}</span
            >
          </span>
          <span v-else class="money">￥{{ actualPrice }}</span>
        </div>
        <div class="pay_btn" @click="handlePay">提交备货金</div>
      </div>

      <Dialog
        v-model.trim="showDialog"
        :confirm-button-color="'#F76D32'"
        confirm-button-text="知道了"
        width="280px"
        class="dialog"
        @confirm="handleConfirm"
      >
        <div class="tip">
          {{ tips }}
        </div>
      </Dialog>
    </div>
    <coupon-select
      ref="refCouponSelect"
      :available-list="availableList"
      :unavailable-list="unavailableList"
      :total-amount="totalAmount"
      @toggleCouponItemSelect2="handleToggleCouponItemSelect"
      @handleCouponConfirm="handleCouponConfirm"
    />
  </div>
</template>

<script>
import wx from 'weixin-js-sdk';
import WxTool from '@/utils/wx-tool';
import {
  Field, Switch, Toast, Dialog, Skeleton,
} from 'vant';
import CommonTitle from '@/components/order/CommonTitle';
import DrugItem from '@/components/order/drug-item';
import Cost from '@/components/order/cost';
import api from '@/api';
import Configs from '@/config';
import { mapMutations, mapActions, mapState } from 'vuex';
import getBaseHeaders from '@/utils/md5';
import { handleAfter401, getFreight } from '@/utils';
import couponMixin from '@/mixins/coupon';

// 回退到`腾讯云医`小程序页面
const handler = () => {
  const { channelEncode } = JSON.parse(sessionStorage.sourceParam);
  if (Configs.txysOrigin[channelEncode] === 'TXYS') {
    wx.miniProgram.navigateBack();
  }
};

export default {
  name: 'SourceToPay',
  components: {
    CommonTitle,
    DrugItem,
    Cost,
    Field,
    MySwitch: Switch,
    Dialog: Dialog.Component,
    Skeleton,
  },
  mixins: [couponMixin],
  data () {
    return {
      coordinate: {},
      detailData: {
        // 配送信息
        shippingInformation: {
          name: '',
          tel: '',
          detailAddress: '',
          address: '',
          location: '',
          axis: {
            latitudes: '',
            longitudes: '',
          },
        },
        drugManInformation: {
          name: '',
          personId: '',
        },
        patientId: '',
        patientName: '',
        diseaseList: [],
        hasAdverse: '', // 不良反应
        hasAllergy: '', // 过敏反应
        hasTake: '', // 禁忌
        product: [],
        invoice: '',
        fileUrlList: [], // 电子的
        prescription: [], // 纸质的
        freight: 10,

        personName: '',
        gender: '',
        age: '',
        phone: '',
        prescriptionType: '',
      },
      switchChecked: false,
      showPreInfo: false,
      showConsignee: true,
      productSum: 0,
      allSum: 0,
      memberPrice: 0,
      allMemberPrice: 0,
      showDialog: false,
      tips: '',
      // createFlag: false,
      count: 0,
      flag: true,
      patientData: {},
      isMember: sessionStorage.getItem('isMember') || 0,
      containPrice: false,
      isJian1Bao: false,
    };
  },
  computed: {
    ...mapState({
      cityInfo: (store) => store.city.cityInfo,
    }),
    packageMailAmount () {
      if (this.defaultSource !== '') {
        return Configs.postageConfig[this.defaultSource].packageMailAmount
      } else {
        return 168;
      }
    },
    defaultSource () {
      return localStorage.getItem('defaultSource') || ''
    }
  },
  beforeRouteEnter (to, from, next) {
    // 检查处方是否已经下过单
    const { prescriptionId, channelEncode } = to.query;

    api.checkIsAlreadyHasOrder({
      prescriptionId,
    }).then((res) => {
      if (
        res.data.code === '0'
        && res.data.data
        && Configs.txysOrigin[channelEncode] === 'TXYS'
      ) {
        // 如果该处方已经下过单了, 那么`直接跳转到订单页面`
        next({
          name: 'detail',
          params: {
            mainOrderNo: res.data.data,
          },
        });
      } else {
        // 如果该处方没下过单, 那么 `next resolve当前路由`
        next();
      }
    });
  },
  mounted () {
    window.addEventListener('popstate', handler);
    if (!Object.keys(this.cityInfo).length) {
      // 如果没有缓存城市信息, 那么在当前页面进行定位
      const wxtool = WxTool.getInstance();
      wxtool.getLocation((res) => {
        // 判断定位是否成功
        if (res.latitude) {
          // 如果成功了, 发起接口
          this.coordinate = res;
          this.getPrescriptionInfo();
        } else if (
          !(
            sessionStorage.sourceParam
            && JSON.parse(sessionStorage.sourceParam).goodsInfo
          )
        ) {
          // 如果失败了 直接进入到城市选择页面
          this.$router.push({
            name: 'city',
          });
        } else {
          // 定位失败 并且不应该进入到城市列表
          this.getPrescriptionInfo();
        }
      });
    } else {
      this.getPrescriptionInfo();
    }
  },
  created () {
    // 判断来源
    const defaultSource = localStorage.getItem('defaultSource') || '';
    this.isJian1Bao = defaultSource && defaultSource === Configs.jian1BaoSource
      ? 'true'
      : false;
  },
  beforeDestroy () {
    window.removeEventListener('popstate', handler);
  },
  methods: {
    ...mapActions('city', ['getCurrentCityInfo']),
    ...mapMutations('pay', ['addInvoiceInfo', 'clearInvoiceInfo']),
    ...mapActions('shoppingCart', ['emptyCartAsync']),
    // 获取处方相关的信息 `处方中的用药人, 症状, 药品等信息`
    getPrescriptionInfo () {
      const { prescriptionId, channelEncode } = JSON.parse(
        sessionStorage.sourceParam,
      );
      const params = {
        prescriptionId,
        origin: Configs.txysOrigin[channelEncode],
      };

      // 定位成功的经纬度
      if (this.coordinate.latitude) {
        params.latitude = this.coordinate.latitude;
        params.longitude = this.coordinate.longitude;
      }

      if (
        sessionStorage.sourceParam
        && JSON.parse(sessionStorage.sourceParam).goodsInfo
      ) {
        let str = '';
        // 如果有zeroNumber
        if (JSON.parse(sessionStorage.sourceParam).zeroNumber) {
          for (
            let i = 0;
            i
            < Number(
              JSON.parse(sessionStorage.sourceParam).zeroNumber,
            );
            i++
          ) {
            str += '=';
          }
        }
        params.goodsInfo = window.atob(
          JSON.parse(sessionStorage.sourceParam).goodsInfo + str,
        );
      }

      // 选择了城市
      if (Object.keys(this.cityInfo).length) {
        params.provincesId = this.cityInfo.provincesId;
        params.cityId = this.cityInfo.cityId;
      }
      api.getDrugAndPatient(params).then((res) => {
        const {
          data: { code, data, message },
        } = res;
        if (code === '0') {
          this.flag = false;
          this.patientData = data;
          this.setInitData();
        } else if (
          code === '21908'
          || code === '21911'
          || code === '999'
          || code === '21918'
        ) {
          console.log(
            'flag',
            Configs.txysOrigin[channelEncode] === 'TXYS',
          );
          Dialog.alert({
            message,
            showConfirmButton: !(
              Configs.txysOrigin[channelEncode] === 'TXYS'
            ),
          }).then(() => {
            this.$router.push({
              name: 'home',
            });
            // sessionStorage.sourceSign = 'SPJYB'; // 健一宝渠道
          });
        } else if (
          code === '21919'
          && Configs.txysOrigin[channelEncode] !== 'TXYS'
        ) {
          Dialog.alert({
            message,
            confirmButtonText: '知道了',
          }).then(() => {
            this.$router.push({
              name: 'home',
            });
          });
        } else if (
          Configs.txysOrigin[channelEncode] === 'TXYS'
          && code !== '21916'
        ) {
          // 腾云医生来源的订单, 直接alert, 没有确认按钮
          Dialog.alert({
            message,
            showConfirmButton: false,
          });
        } else if (code === '21916') {
          // 腾讯来源的, 处方已经使用过(下过订单了)
          this.$router.push({
            name: 'detail',
            params: {
              mainOrderNo: message,
            },
          });
        } else {
          Toast.fail({
            duration: 3000,
            message,
          });
        }
      });
    },

    setInitData () {
      // 收货地址信息
      const { consigneeInfo, QQAddress } = this.$store.state.consignee;
      const { invoiceInfo } = this.$store.state.pay;

      // 处方信息
      const {
        personId,
        personName,
        gender,
        age,
        orderDrugs,
        idNo,
      } = this.patientData;

      this.detailData.patientId = personId;
      this.detailData.personName = personName;
      this.detailData.gender = gender;
      this.detailData.age = age;
      this.detailData.idNo = idNo;

      this.detailData.product = orderDrugs;
      // product中添加药店名称
      for (let i = 0; i < this.detailData.product.length; i++) {
        this.productSum += this.detailData.product[i].totalPrice;
        if (this.isMember > 0) {
          if (this.detailData.product[i].memberPrice > 0) {
            this.containPrice = true; // 如果是会员，选的会员药，不显示划横线
          }
          this.memberPrice
            += this.detailData.product[i].count
            * (this.detailData.product[i].memberPrice > 0
              ? this.detailData.product[i].memberPrice
              : this.detailData.product[i].productPrice);
        }
      }
      this.productSum = this.productSum.toFixed(2);
      this.memberPrice = this.memberPrice.toFixed(2);

      this.drugStoreCode = this.detailData.product[0].drugstoreCode;

      //= 根据购物车合计数据 计算邮费 ===============================================================================
      this.detailData.freight = getFreight(this);

      // 计算合计
      this.allSum = (
        Number(this.productSum) + this.detailData.freight
      ).toFixed(2);
      this.allMemberPrice = (
        Number(this.memberPrice) + this.detailData.freight
      ).toFixed(2);

      // 收获地址处理
      if (consigneeInfo.name) {
        this.showConsignee = false;
        this.detailData.shippingInformation = { ...consigneeInfo };
        if (QQAddress.province === QQAddress.city) {
          this.detailData.shippingInformation.location = QQAddress.province + QQAddress.district;
        } else {
          this.detailData.shippingInformation.location = QQAddress.province
            + QQAddress.city
            + QQAddress.district;
        }
        this.detailData.shippingInformation.address += this.detailData.shippingInformation.detailAddress;
        this.detailData.shippingInformation.axis.latitudes = QQAddress.location.lat;
        this.detailData.shippingInformation.axis.longitudes = QQAddress.location.lng;
      }

      if (invoiceInfo.invoice || invoiceInfo.switchChecked) {
        this.detailData.invoice = invoiceInfo.invoice;
        this.switchChecked = invoiceInfo.switchChecked;
      }

      if (!this.isCustom) {
        // 用户没有自定义过
        this.getCouponList();
      } else {
        this.userUpdateCouponSelect(this.cardIds).then((res) => {
          this.processCoupon(res);
          this.deductRelList = this.relation;
          this.hasSelectedCardAmount = res.data.totalAmount;
        });
      }
    },

    // @debounce(500);
    // 去支付逻辑
    async handlePay () {
      this.count += 1;
      const {
        product, age, gender, idNo, personName,
      } = this.detailData;

      const {
        prescribeDate,
        hospitalName,
        departmentName,
        diagnoseName,
        prescriptionExpriedDate,
        prescriptionUrl,
        doctorName,
      } = this.patientData;
      const { prescriptionId, channelEncode } = JSON.parse(
        sessionStorage.sourceParam,
      );

      const homeUrl = `${window.location.origin}/pysc/home`;
      const parma = {
        ...this.detailData,
        merchantCode: product[0].drugstoreCode || '',
        homeUrl,
        detailUrl: `${window.location.origin}/pysc/detail/`,
        count: this.count,
        prescriptionId,
        drugManInformation: {
          name: personName,
          idNo,
          gender,
          age,
        },
        prescribeDate,
        hospitalName,
        departmentName,
        diagnoseName,
        prescriptionExpriedDate,
        originStatus: Configs.txysOrigin[channelEncode],
        prescription: [prescriptionUrl],
        idNo,
        doctorName,
        isMember: this.isMember,
        shareCode:
          window.localStorage.getItem('shareCode') || undefined,
      };
      if (parma.shareCode) {
        parma.qrCodeData = Configs.qrCodeData;
      }
      parma.signature = getBaseHeaders(parma);

      const notPassVerify = this.verifyBeforePay();
      if (notPassVerify) return;

      if (window.__wxjs_environment === 'miniprogram') {
        // 是小程序环境
        parma.clientSide = 'applets';
      }

      parma.deductRelList = this.relation;

      const ua = window.navigator.userAgent;
      if (ua.includes && ua.includes('wxwork')) {
        parma.clientSide = 'wxwork';
      }

      api.postCreateOrder(parma).then((res) => {
        // this.createFlag = true;
        // 去支付

        if (res.data.code === '0') {
          // 创建成功，清除数据
          this.clerData();

          const {
            orderCacheId,
            paidFlag,
            mainOrderNo,
          } = res.data.data;

          let url = '';

          if (paidFlag) {
            // 会员卡全额支付的订单, 直接跳转到支付成功页面
            url = `${Configs.payUrl}plugin/pages/success/index?paymentId=${orderCacheId}&type=1`;
          } else {
            // 正常需要走支付流程的订单
            url = `${Configs.payUrl}?orderCacheId=${orderCacheId}`;
          }

          if (Configs.txysOrigin[channelEncode] === 'TXYS') {
            // 腾讯来源的, 需要去除`返回首页`按钮
            url = `${url}&isHiddenBackHomeBtn=1`;
          }
          localStorage.removeItem('shareCode');
          this.emptyCartAsync({
            merchantCode: parma.merchantCode,
            cb: () => {
              // 判断环境
              if (window.__wxjs_environment === 'miniprogram') {
                // 微信环境
                wx.miniProgram.navigateTo({
                  url: `/pages/pay/pay?orderCacheId=${orderCacheId}&mainOrderNo=${mainOrderNo}`,
                });
              } else {
                // 非微信环境
                window.location.href = url;
              }
            },
          });
          return false;
        }
        // if (res.data.code === '21030' || res.data.code === '21911' || res.data.code === '9999') {
        if (
          ['21030', '21911', '9999', '21917'].includes(res.data.code)
        ) {
          this.showDialog = true;
          this.tips = res.data.message;
          parma.count = 0;
          this.count = 0;
          return false;
        }

        if (
          res.data.code === '21915'
          && Configs.txysOrigin[channelEncode] === 'TXYS'
        ) {
          this.count = 0;
          Dialog.alert({
            message: res.data.message,
            confirmButtonText: '知道了',
          });
          return false;
        }

        if (res.data.code === '21912') {
          this.count = 0;
          // 腾讯来源的已经下过单了
          Dialog.alert({
            message: '思派云药房需要您授权登录',
            confirmButtonText: '去登录',
          }).then(() => {
            api.logout({
              token: localStorage.getItem('authentication'),
            }).then((logoutRes) => {
              if (logoutRes.data.code === '0') {
                localStorage.removeItem('authentication');
                localStorage.removeItem('userId');
                localStorage.removeItem('phone');
                handleAfter401();
              }
            });
          });
          return false;
        }

        // 创建订单时, 会员卡余额变动, 弹窗提示
        if (res.data.code === '21921') {
          this.count = 0;
          Dialog.alert({
            message: res.data.message,
            confirmButtonText: '知道了',
          }).then(() => {
            this.userUpdateCouponSelect(this.cardIds).then(
              (result) => {
                this.processCoupon(result, true);
              },
            );
          });
          return false;
        }

        if (res.data.code === '21916') {
          this.count = 0;
          // 腾讯来源的已经下过单了
          Dialog.alert({
            message: '该订单已提交',
          }).then(() => {
            this.$router.push({
              name: 'detail',
              params: {
                mainOrderNo: res.data.message,
              },
            });
          });
          return false;
        }

        Toast.fail({
          duration: 3000,
          message: res.data.message,
        });
        parma.count = 0;
        this.count = 0;
      });
    },
    clerData () {
      this.detailData = {
        // 配送信息
        shippingInformation: {
          name: '',
          tel: '',
          detailAddress: '',
          address: '',
          location: '',
          axis: {
            latitudes: '',
            longitudes: '',
          },
        },
        drugManInformation: {
          name: '',
          personId: '',
        },
        patientId: '',
        patientName: '',
        diseaseList: [],
        hasAdverse: '', // 不良反应
        hasAllergy: '', // 过敏反应
        hasTake: '', // 禁忌
        product: [],
        invoice: 0, // 不需要发票
        fileUrlList: [], // 电子的
        prescription: [], // 纸质的
        freight: 10,

        personName: '',
        gender: '',
        age: '',
        phone: '',
        prescriptionType: '',
      };
      this.switchChecked = false;
      this.showPreInfo = false;
      this.showConsignee = true;
      this.productSum = 0;
      this.allSum = 0;
      this.hasSelectedCardAmount = 0;
    },
    goAddress () {
      this.$router.push({
        name: 'add-address',
        query: { formPage: 'sourceToPay' },
      });
    },
    switchChange () {
      if (this.switchChecked) {
        const param = {
          switchChecked: this.switchChecked,
          invoice: this.detailData.invoice,
        };
        this.addInvoiceInfo({ ...param });
      } else {
        this.clearInvoiceInfo();
      }
    },
    blurInvoice () {
      if (this.detailData.invoice) {
        const param = {
          switchChecked: this.switchChecked,
          invoice: this.detailData.invoice,
        };
        this.addInvoiceInfo({ ...param });
      } else {
        this.clearInvoiceInfo();
      }
    },
    verifyBeforePay () {
      if (
        !this.detailData.shippingInformation.name
        || !this.detailData.shippingInformation.tel
        || !this.detailData.shippingInformation.detailAddress
        || !this.detailData.shippingInformation.address
        || !this.detailData.shippingInformation.address
        || !this.detailData.shippingInformation.axis.latitudes
        || !this.detailData.shippingInformation.axis.longitudes
      ) {
        Toast('请选择收货地址');
        return true;
      }

      if (this.switchChecked && !this.detailData.invoice) {
        Toast('请填写发票抬头');
        return true;
      }

      return false;
    },
    handleConfirm () {
      this.showDialog = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import './mixin.scss';

.pay_box /deep/ .van-popup {
  height: 80%;
}

.pay_box /deep/ .van-popup .van-action-sheet__content {
  height: 100% !important;
}

.pay_box /deep/ .van-popup .van-action-sheet__content .content {
  height: 100% !important;
}

.pay_box {
  padding-top: 8px;
  // padding-bottom: constant(safe-area-inset-bottom);
  height: 100%;
  .addr_info {
    position: relative;
    background: #fff;
    margin-bottom: 8px;
    .add_addr {
      height: 56px;
      // line-height: 56px;
      text-align: center;
      .btn_addr {
        margin-top: 11px;
        height: 32px;
        background: linear-gradient(128deg, #ffd169 0%, #fd6500 100%);
        border-radius: 21px;
        font-size: 14px;
        font-weight: 500;
        padding: 6px 15px;
        color: #fff;
        border: none;
      }
    }

    .addressee {
      font-size: 16px;
      position: relative;
      margin-bottom: 8px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding-right: 16px;
      padding-bottom: 12px;
      .namephone {
        // height: 22px;
        line-height: 22px;
        padding: 18px 18px 8px;
        font-weight: 600;
        color: #333;
        display: flex;
        &::before {
          content: '';
          display: inline-block;
          width: 16px;
          height: 16px;
          position: relative;
          top: 2px;
          background: url('~@img/phone.png') no-repeat;
          background-size: 16px 16px;
          margin-right: 8px;
        }
        .name_phone {
          flex: 1;
        }
        .name {
          margin-right: 16px;
        }
        // .phone {
        //     padding-left: 16px;
        // }
      }
      .address {
        display: flex;
        padding: 0 18px;
        .address_txt {
          color: #333;
          font-weight: 400;
          line-height: 22px;
          width: 287px;
          word-wrap: break-word;
          word-break: break-all;
          white-space: pre-wrap;
        }
        &::before {
          content: '';
          display: inline-block;
          width: 16px;
          height: 16px;
          position: relative;
          top: 2px;
          background: url('~@img/icon_location.png') no-repeat;
          background-size: 16px 16px;
          margin-right: 8px;
        }
      }
    }
    .group {
      width: 100%;
      height: 4px;
      position: absolute;
      bottom: 0;
    }
  }
  .pre_info {
    margin-bottom: 8px;
    background: #fff;
    padding: 16px 18px;
    .fill_pre {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .left_fill {
        .fill_in {
          height: 22px;
          font-size: 16px;
          font-weight: 500;
          color: #333;
          line-height: 22px;
          margin-bottom: 4px;
          &::before {
            content: '';
            display: inline-block;
            width: 16px;
            height: 16px;
            position: relative;
            top: 1px;
            background: url('~@img/rp.png') no-repeat;
            background-size: 16px 16px;
            margin-right: 3px;
          }
        }
        .txt_in {
          height: 20px;
          font-size: 14px;
          font-weight: 400;
          color: #62636c;
          line-height: 20px;
        }
      }
      .right_btn {
        // width: 74px;
        height: 32px;
        line-height: 32px;
        background: #fff;
        border-radius: 16px;
        border: 1px solid #f36218;
        font-size: 14px;
        font-weight: 500;
        color: #f36218;
        text-align: center;
        margin-top: 4px;
      }
    }
  }
  .completed_pre {
    background: #fff;
    padding: 16px 15px 18px 18px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 8px;
    .completed_info {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      word-break: break-all;
      .completed_title,
      .completed_name {
        height: 22px;
        font-size: 16px;
        color: #333;
        line-height: 22px;
        margin-bottom: 6px;
        .tag {
          display: inline-block;
          width: 44px;
          height: 16px;
          line-height: 16px;
          font-size: 11px;
          color: #fff;
          text-align: center;
          background: url('~@img/tag_bg.png') no-repeat;
          background-size: contain;
          margin-right: 5px;
        }
      }
      .completed_title {
        font-weight: 500;
        &::before {
          content: '';
          display: inline-block;
          width: 16px;
          height: 16px;
          position: relative;
          background: url('~@img/rp.png') no-repeat;
          background-size: 16px 16px;
          margin-right: 4px;
        }
      }
      .completed_name {
        font-weight: 400;
      }
      .completed_txt {
        font-size: 14px;
        font-weight: 400;
        color: #333;
        line-height: 22px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        word-break: break-all;
      }
    }
  }
  .card {
    background: #fff;
    margin-bottom: 8px;
    .drug_list {
      padding: 0 18px;
      .num {
        height: 20px;
        font-size: 14px;
        font-weight: 400;
        color: #666;
        line-height: 20px;
      }
    }
  }

  .common_info {
    margin-bottom: 8px;
    background: white;
    .purchase {
      padding: 16px 18px;
      font-size: 14px;
      font-weight: 400;
      color: #62636c;
      p {
        line-height: 22px;
      }
    }
    .sw {
      margin-top: 9px;

      @include switchTag();
    }

    .coupon {
      display: flex;
      align-content: center;
      justify-content: center;
      font-size: 14px;
      font-weight: 500;
      color: #666;
    }
    .card_amount {
      font-size: 14px;
      font-weight: 500;
      color: #f0524f;
    }
    .unselected_coupon {
      font-size: 14px;
      font-weight: 500;
      color: #666;
    }
    .arrow_container {
      display: flex;
      align-items: center;
      justify-content: center;
      .arrow_right {
        width: 12px;
        height: 12px;
      }
    }
    &.purchase_info {
      padding-bottom: 50px;
    }
  }
  .footer_btns {
    position: fixed;
    bottom: constant(safe-area-inset-bottom);
    bottom: env(safe-area-inset-bottom);
    bottom: 0;
    display: flex;
    width: 100%;
    height: 48px;
    line-height: 48px;
    box-shadow: 0 2px 8px 0 rgba(68, 134, 251, 0.12);
    .sum_btn {
      background: #fff;
      flex: 1;
      padding-left: 18px;
      font-size: 20px;
      font-weight: 500;
      color: #fa3e3e;
      .sum_txt {
        font-size: 14px;
        font-weight: 400;
        color: #62636c;
      }
      .icon_money {
        font-size: 14px;
      }
      .money {
        font-size: 20px;
        font-weight: 500;
        color: #fa3e3e;
      }
      .origin_price {
        font-size: 14px;
        font-weight: 500;
        color: rgba(0, 0, 0, 0.35);
        margin-left: 15px;
        text-decoration: line-through;
      }
    }
    .pay_btn {
      text-align: center;
      font-size: 16px;
      font-weight: 600;
      color: #fff;
      background: #f36218;
      width: 141px;
    }
  }
  .right_arrow {
    display: flex;
    align-items: center;
    img {
      width: 12px;
      height: 12px;
    }
  }
}

/deep/ .van-field__control {
  text-align: right;
}
.tip {
  padding: 24px 22px;
  font-size: 16px;
  // font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #000;
  line-height: 25px;
  background: #fff;
  text-align: center;
}
</style>
